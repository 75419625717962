import { useMemo } from "react";
import {
  ChainId,
  Currency,
  CurrencyAmount,
  Percent,
  Token,
  TradeType,
} from "@uniswap/sdk-core";
import {
  FeeAmount,
  Pool,
  Route,
  SwapQuoter,
  computePoolAddress,
} from "@uniswap/v3-sdk";
import JSBI from "jsbi";

import { useSingleContractWithCallData } from "lib/hooks/multicall";
import { useQuoter } from "hooks/useContract";
import {
  InterfaceTrade,
  IInterfaceTrade,
  TradeState,
  ClassicTrade,
  QuoteMethod,
} from "state/routing/types";
import { useChainId } from "wagmi";

import { isCelo } from "constants/tokens";
import { useAllV3Routes } from "./useAllV3Routes";
// import { getUnderlyingToken } from "config/contracts";
// import { SEPOLIA } from "constants/chains";
// import { SupportedChainId } from 'constants/chains'

const QUOTE_GAS_OVERRIDES: { [chainId: number]: number } = {
  [ChainId.ARBITRUM_ONE]: 25_000_000,
  [ChainId.ARBITRUM_GOERLI]: 25_000_000,
  [ChainId.CELO]: 50_000_000,
  [ChainId.CELO_ALFAJORES]: 50_000_000,
  [ChainId.POLYGON]: 40_000_000,
  [ChainId.POLYGON_MUMBAI]: 40_000_000,
};

const DEFAULT_GAS_QUOTE = 2_000_000;

// const aaveDAI = getUnderlyingToken("DAI");

// const SEPOLIA_POOLS: string[] = [
//   // [aaveDAI, getUnderlyingToken('WBTC'), ],
//   [aaveDAI, getUnderlyingToken("LINK"), 500],
//   [aaveDAI, getUnderlyingToken("EURS"), 500],
//   [aaveDAI, getUnderlyingToken("USDC"), 500],
//   [aaveDAI, getUnderlyingToken("USDT"), 500],
// ].map((x) =>
//   computePoolAddress({
//     factoryAddress: "0x0227628f3F023bb0B980b67D528571c95c6DaC1c",
//     tokenA: x[0] as any,
//     tokenB: x[1] as any,
//     fee: x[2] as any,
//   })
// );

// const poolValid = (pool: Pool) => {
//   return SEPOLIA_POOLS.includes(
//     computePoolAddress({
//       factoryAddress: "0x0227628f3F023bb0B980b67D528571c95c6DaC1c",
//       tokenA: pool.token0,
//       tokenB: pool.token1,
//       fee: pool.fee,
//     })
//   );
// };

/**
 * Returns the best v3 trade for a desired swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useClientSideV3Trade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState;
  trade: InterfaceTrade | undefined;
} {
  const [currencyIn, currencyOut] =
    tradeType === TradeType.EXACT_INPUT
      ? [amountSpecified?.currency, otherCurrency]
      : [otherCurrency, amountSpecified?.currency];

  const { routes, loading: routesLoading } = useAllV3Routes(
    currencyIn,
    currencyOut
  );

  const chainId = useChainId();

  // Chains deployed using the deploy-v3 script only deploy QuoterV2.
  // Sepolia actually uses quoter V2, too
  const useQuoterV2 = isCelo(chainId) || chainId === ChainId.SEPOLIA;

  const quoter = useQuoter(useQuoterV2);

  const callData = useMemo(
    () =>
      amountSpecified
        ? routes.map(
            (route) =>
              SwapQuoter.quoteCallParameters(
                route,
                amountSpecified,
                tradeType,
                {
                  useQuoterV2,
                }
              ).calldata
          )
        : [],
    [amountSpecified, routes, tradeType, useQuoterV2]
  );

  const _quotesResults = useSingleContractWithCallData(quoter, callData, {
    gasRequired: chainId
      ? QUOTE_GAS_OVERRIDES[chainId] ?? DEFAULT_GAS_QUOTE
      : undefined,
  });
  const quotesResults = _quotesResults; //.filter((_, i) => relevantIndexes.includes(i))

  return useMemo(() => {
    if (
      !amountSpecified ||
      !currencyIn ||
      !currencyOut ||
      quotesResults.some(({ valid }) => !valid) ||
      // skip when tokens are the same
      (tradeType === TradeType.EXACT_INPUT
        ? amountSpecified.currency.equals(currencyOut)
        : amountSpecified.currency.equals(currencyIn))
    ) {
      return {
        state: TradeState.INVALID,
        trade: undefined,
      };
    }

    if (routesLoading || quotesResults.some(({ loading }) => loading)) {
      return {
        state: TradeState.LOADING,
        trade: undefined,
      };
    }

    const { bestRoute, amountIn, amountOut } = quotesResults.reduce(
      (
        currentBest: {
          bestRoute: Route<Currency, Currency> | null;
          amountIn: CurrencyAmount<Currency> | null;
          amountOut: CurrencyAmount<Currency> | null;
        },
        { result },
        i
      ) => {
        if (!result) return currentBest;

        // overwrite the current best if it's not defined or if this route is better
        if (tradeType === TradeType.EXACT_INPUT) {
          const amountOut = CurrencyAmount.fromRawAmount(
            currencyOut,
            result.amountOut.toString()
          );
          if (
            currentBest.amountOut === null ||
            JSBI.lessThan(currentBest.amountOut.quotient, amountOut.quotient)
          ) {
            return {
              bestRoute: routes[i],
              amountIn: amountSpecified,
              amountOut,
            };
          }
        } else {
          const amountIn = CurrencyAmount.fromRawAmount(
            currencyIn,
            result.amountIn.toString()
          );
          if (
            currentBest.amountIn === null ||
            JSBI.greaterThan(currentBest.amountIn.quotient, amountIn.quotient)
          ) {
            return {
              bestRoute: routes[i],
              amountIn,
              amountOut: amountSpecified,
            };
          }
        }
        return currentBest;
      },
      {
        bestRoute: null,
        amountIn: null,
        amountOut: null,
      }
    );

    if (!bestRoute || !amountIn || !amountOut) {
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: undefined,
      };
    }

    // const approveInfo = await getApproveInfo(account, currencyIn, amount, usdCostPerGas)

    return {
      state: TradeState.VALID,
      trade: new ClassicTrade({
        v2Routes: [],
        v3Routes: [
          {
            routev3: bestRoute,
            inputAmount: amountIn,
            outputAmount: amountOut,
          },
        ],
        tradeType,
        swapFee: { recipient: "", percent: new Percent(1, 1), amount: "0" },
        approveInfo: { needsApprove: false },
        gasUseEstimateUSD: 0,
        blockNumber: null,
        requestId: undefined,
        quoteMethod: QuoteMethod.CLIENT_SIDE_FALLBACK,
      }),
    };
  }, [
    amountSpecified,
    currencyIn,
    currencyOut,
    quotesResults,
    routes,
    routesLoading,
    tradeType,
  ]);
}
