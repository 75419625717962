import { CONTRACTS } from './contracts'

export function getAavePoolAddress (chainId: number): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  return CONTRACTS[chainId]['aavePool']
}

export function getLekkerFactory (chainId: number | undefined): string {
  if (chainId === undefined || !CONTRACTS[chainId]) {
    return ''
  }

  return CONTRACTS[chainId]!['lekkerFactory']
}

export function getAllLekkerPools (chainId: number): string[] {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  return CONTRACTS[chainId]['allLekkerPools']
}

export function getLekkerPool (
  chainId: number,
  lendingProtocol: string,
  collateral: string,
  debt: string
): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  if (!CONTRACTS[chainId][lendingProtocol]) {
    throw new Error(
      `Unknown contract "${lendingProtocol}" for chainId ${chainId}`
    )
  }

  return CONTRACTS[chainId][lendingProtocol][collateral][debt]
}

export const getLensContract = (chainId: number) => {
  // Open new position hook
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`)
  }

  return CONTRACTS[chainId]['lekkerLens']
}
