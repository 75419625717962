import { Currency } from "@uniswap/sdk-core";

export function formatCurrencySymbol(
  currency?: Currency,
  otherCurrency?: Currency | null | undefined
): string | undefined {
  let formattedSymbol: string | undefined;

  // Prioritize otherCurrency if available, otherwise use currency
  if (otherCurrency !== null && otherCurrency?.symbol) {
    formattedSymbol = otherCurrency.symbol + "/" + currency?.symbol;
    // Apply formatting to the selected symbol if it exceeds a certain length
    if (formattedSymbol && formattedSymbol.length > 20) {
      formattedSymbol =
        formattedSymbol.slice(0, 4) +
        "..." +
        formattedSymbol.slice(formattedSymbol.length - 5);
    }
  } else if (currency && currency.symbol) {
    formattedSymbol = currency.symbol;
    // Apply formatting to the selected symbol if it exceeds a certain length
    if (formattedSymbol && formattedSymbol.length > 20) {
      formattedSymbol =
        formattedSymbol.slice(0, 4) +
        "..." +
        formattedSymbol.slice(formattedSymbol.length - 5);
    }
  }

  return formattedSymbol;
}
