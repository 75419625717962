import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PositionData, AssetState } from './types';




const initialState: AssetState = {
  positions: {},
  loading: {},
  errors: {},
};

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    updatePositionData: (state, action: PayloadAction<{ chainId: number; data: PositionData[] }>) => {
      const { chainId, data } = action.payload;
      state.positions[chainId] = data;
      state.loading[chainId] = false; // Clear loading state when data is fetched

      state.errors[chainId] = null;
    },

     // Set loading state for a specific chainId
     setLoading: (state, action: PayloadAction<{ chainId: number; isLoading: boolean }>) => {
      const { chainId, isLoading } = action.payload;
      state.loading[chainId] = isLoading;
    },

    setPositionDataError: (state, action: PayloadAction<{ chainId: number; error: string }>) => {
      const { chainId, error } = action.payload;
      state.errors[chainId] = error;
      state.loading[chainId] = false; // Clear loading state on error

    },


    resetState: (state) => {
      return initialState;
    },
  },
});

export const { updatePositionData, setPositionDataError, setLoading, resetState } = assetSlice.actions;
export default assetSlice.reducer;
