import styled from 'styled-components'

interface SliderTooltipProps {
  theme?: any
}

export const SliderTooltip = styled.div<SliderTooltipProps>`
  color: ${({ theme }) => theme?.neutral1 || '#ffffff'};
  font-size: ${({ theme }) => theme?.fontSize || '12px'};
  font-family: ${({ theme }) => theme?.fontFamily || 'Inter, sans-serif'};
  font-weight: ${({ theme }) => theme?.fontWeight};
  white-space: ${({ theme }) => theme?.whiteSpace || 'nowrap'};
  position: relative;
`
