import {
  ChainId,
  Currency,
  CurrencyAmount,
  TradeType,
} from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { Field } from "components/swap/constants";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useDebouncedTrade } from "hooks/useDebouncedTrade";
import { useSwapTaxes } from "hooks/useSwapTaxes";
import { useUSDPrice } from "hooks/useUSDPrice";
import { Trans } from "i18n";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import {
  isClassicTrade,
  isSubmittableTrade,
  isUniswapXTrade,
} from "state/routing/utils";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";
import { isAddress } from "utilities/src/addresses";

import { useSupportedChainId } from "constants/chains";
import { useCurrency } from "hooks/Tokens";
import useParsedQueryString from "hooks/useParsedQueryString";
import { getParsedChainId } from "hooks/useSyncChainQuery";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import {
  IInterfaceTrade,
  InterfaceTrade,
  RouterPreference,
  TradeState,
} from "state/routing/types";
import { useAccount, useChainId } from "wagmi";
import { useCurrencyBalance, useCurrencyBalances } from "../connection/hooks";
import {
  CurrencyState,
  SerializedCurrencyState,
  SwapAndLimitContext,
  SwapContext,
  SwapInfo,
  SwapState,
  parseIndependentFieldURLParameter,
} from "./types";
import { useClientSideV3Trade } from "state/routing/useClientSideV3Trade";

export function useSwapContext() {
  return useContext(SwapContext);
}

export function useSwapAndLimitContext() {
  return useContext(SwapAndLimitContext);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: (options: {
    newOutputHasTax: boolean;
    previouslyEstimatedOutput: string;
  }) => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const { swapState, setSwapState } = useSwapContext();
  const { currencyState, setCurrencyState } = useSwapAndLimitContext();

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const [currentCurrencyKey, otherCurrencyKey]: (keyof CurrencyState)[] =
        field === Field.INPUT
          ? ["inputCurrency", "outputCurrency"]
          : ["outputCurrency", "inputCurrency"];
      const otherCurrency = currencyState[otherCurrencyKey];
      // the case where we have to swap the order
      if (otherCurrency && currency.equals(otherCurrency)) {
        setCurrencyState({
          [currentCurrencyKey]: currency,
          [otherCurrencyKey]: currencyState[currentCurrencyKey],
        });
        setSwapState((swapState) => ({
          ...swapState,
          independentField:
            swapState.independentField === Field.INPUT
              ? Field.OUTPUT
              : Field.INPUT,
        }));
      } else {
        setCurrencyState((state) => ({
          ...state,
          [currentCurrencyKey]: currency,
        }));
      }
    },
    [currencyState, setCurrencyState, setSwapState]
  );

  const onSwitchTokens = useCallback(
    ({
      newOutputHasTax,
      previouslyEstimatedOutput,
    }: {
      newOutputHasTax: boolean;
      previouslyEstimatedOutput: string;
    }) => {
      // To prevent swaps with FOT tokens as exact-outputs, we leave it as an exact-in swap and use the previously estimated output amount as the new exact-in amount.
      if (newOutputHasTax && swapState.independentField === Field.INPUT) {
        setSwapState((swapState) => ({
          ...swapState,
          typedValue: previouslyEstimatedOutput,
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField:
            prev.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        }));
      }

      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency,
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField]
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSwapState((state) => {
        return {
          ...state,
          independentField: field,
          typedValue,
        };
      });
    },
    [setSwapState]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  };
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(state: SwapState): SwapInfo {
  const { account, chainId } = useWeb3React();
  const nativeCurrency = useNativeCurrency(chainId);
  const balance = useCurrencyBalance(account ?? undefined, nativeCurrency);

  const {
    currencyState: { inputCurrency, outputCurrency, leverage },
  } = useSwapAndLimitContext();
  const { independentField, typedValue } = state;

  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken ? inputCurrency.address : undefined,
    outputCurrency?.isToken ? outputCurrency.address : undefined
  );

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  // const parsedAmount = useMemo(
  //   () =>
  //     tryParseCurrencyAmount(
  //       typedValue,
  //       (isExactIn ? inputCurrency : outputCurrency) ?? undefined
  //     ),
  //   [inputCurrency, isExactIn, outputCurrency, typedValue]
  // );

  // const trade: {
  //   state: TradeState
  //   trade?: InterfaceTrade
  //   swapQuoteLatency?: number
  // } = useDebouncedTrade(
  //   isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
  //   parsedAmount,
  //   (isExactIn ? outputCurrency : inputCurrency) ?? undefined,
  //   state.routerPreferenceOverride as RouterPreference.API | undefined,
  //   account
  // )

  // This is the ammount obtained from the swap - as it's ExactOutput, it's the amount of collateral required
  // If the user types a desired total size of the position, we make a reverse calculation
  const parsedAmount = useMemo(() => {
    // Convert typedValue to a number if possible, otherwise keep it as a string
    let amount = Number(typedValue);

    // Multiply the converted amount by 3
    if (isExactIn) {
      leverage ? (amount *= leverage - 1) : (amount *= 2 - 1);
    } else {
      leverage ? (amount -= amount /= leverage) : (amount /= 2);
    }

    // Use the modified amount in your tryParseCurrencyAmount function
    return tryParseCurrencyAmount(
      amount.toString(),
      inputCurrency ? inputCurrency : undefined
    );
  }, [inputCurrency, leverage, outputCurrency, typedValue]);

  const trade: {
    state: TradeState;
    trade?: InterfaceTrade;
    swapQuoteLatency?: number;
  } = useClientSideV3Trade(
    //(exactOutput, 2, dai)
    TradeType.EXACT_OUTPUT,
    // This is inputCurrency (collateral)
    parsedAmount, //debounceTradeOutputAmount,
    // This is outputCurrency (debt)
    outputCurrency ? outputCurrency : undefined
    //(isExactIn ? outputCurrency : inputCurrency) ?? undefined
  );

  const { data: nativeCurrencyBalanceUSD } = useUSDPrice(
    balance,
    nativeCurrency
  );

  const { data: outputFeeFiatValue } = useUSDPrice(
    isSubmittableTrade(trade.trade) && trade.trade.swapFee
      ? CurrencyAmount.fromRawAmount(
          trade.trade.outputAmount.currency,
          trade.trade.swapFee.amount
        )
      : undefined,
    trade.trade?.outputAmount.currency
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  );

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(
    isClassicTrade(trade.trade) ? trade.trade : undefined
  );

  // slippage for uniswapx trades is defined by the quote response
  const uniswapXAutoSlippage = isUniswapXTrade(trade.trade)
    ? trade.trade.slippageTolerance
    : undefined;

  // Uniswap interface recommended slippage amount
  const autoSlippage = uniswapXAutoSlippage ?? classicAutoSlippage;
  const classicAllowedSlippage =
    useUserSlippageToleranceWithDefault(autoSlippage);

  // slippage amount used to submit the trade
  const allowedSlippage = uniswapXAutoSlippage ?? classicAllowedSlippage;

  // totalGasUseEstimateUSD is greater than native token balance
  const insufficientGas =
    isClassicTrade(trade.trade) &&
    (nativeCurrencyBalanceUSD ?? 0) <
      (trade.trade.totalGasUseEstimateUSDWithBuffer ?? 0);

  const { isDisconnected } = useAccount();
  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = isDisconnected ? (
        <Trans>Connect wallet</Trans>
      ) : (
        <Trans>Connecting wallet...</Trans>
      );
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>;
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>;
    }

    if (insufficientGas) {
      inputError = (
        <Trans>Insufficient {{ symbol: nativeCurrency.symbol }} balance</Trans>
      );
    }

    // compare input balance to max input based on version
    const [balanceIn, maxAmountIn] = [
      currencyBalances[Field.INPUT],
      trade?.trade?.maximumAmountIn(allowedSlippage),
    ];

    // TODO: Review this scenario
    // if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
    //   console.log(
    //     "~> ~ file: hooks.tsx:311 ~ inputError ~ balanceIn:",
    //     balanceIn
    //   );

    //   console.log(
    //     "~> ~ file: hooks.tsx:311 ~ inputError ~ maxAmountIn:",
    //     maxAmountIn
    //   );

    //   inputError = (
    //     <Trans>
    //       Insufficient {{ symbol: balanceIn.currency.symbol }} balance
    //     </Trans>
    //   );
    // }

    return inputError;
  }, [
    account,
    currencies,
    parsedAmount,
    currencyBalances,
    trade?.trade,
    allowedSlippage,
    isDisconnected,
    insufficientGas,
    nativeCurrency.symbol,
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
      outputFeeFiatValue,
      leverage,
      inputTax,
      outputTax,
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      outputFeeFiatValue,
      parsedAmount,

      trade,
      leverage,
      inputTax,
      outputTax,
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") return "ETH";
  }
  return "";
}

export function queryParametersToCurrencyState(
  parsedQs: ParsedQs
): SerializedCurrencyState {
  let inputCurrency = parseCurrencyFromURLParameter(
    parsedQs.inputCurrency ?? parsedQs.inputcurrency
  );
  let outputCurrency = parseCurrencyFromURLParameter(
    parsedQs.outputCurrency ?? parsedQs.outputcurrency
  );
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );
  const chainId = getParsedChainId(parsedQs);

  if (
    inputCurrency === "" &&
    outputCurrency === "" &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    inputCurrency = "ETH";
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  return {
    inputCurrencyId:
      inputCurrency === "" ? undefined : inputCurrency ?? undefined,
    outputCurrencyId:
      outputCurrency === "" ? undefined : outputCurrency ?? undefined,
    chainId,
  };
}

export function useInitialCurrencyState(): {
  initialInputCurrency?: Currency;
  initialOutputCurrency?: Currency;
  chainId: ChainId;
} {
  const parsedQs = useParsedQueryString();
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs);
  }, [parsedQs]);

  const connectedChainId = useChainId();
  const chainId =
    useSupportedChainId(parsedCurrencyState.chainId ?? connectedChainId) ??
    ChainId.MAINNET;

  const initialInputCurrency = useCurrency(
    parsedCurrencyState.inputCurrencyId,
    chainId
  );
  const initialOutputCurrency = useCurrency(
    parsedCurrencyState.outputCurrencyId,
    chainId
  );

  return { initialInputCurrency, initialOutputCurrency, chainId };
}
