import { ChainId, Currency, Token, WETH9 } from "@uniswap/sdk-core";
import {
  getNativeLogoURI,
  getTokenLogoURI,
} from "lib/hooks/useCurrencyLogoURIs";
import { SafetyLevel } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { CurrencyInfo } from "uniswap/src/features/dataApi/types";
import {
  ARB,
  BTC_BSC,
  BUSD_BSC,
  CEUR_CELO,
  CEUR_CELO_ALFAJORES,
  CUSD_CELO,
  CUSD_CELO_ALFAJORES,
  DAI,
  DAI_ARBITRUM_ONE,
  DAI_AVALANCHE,
  DAI_BSC,
  DAI_OPTIMISM,
  DAI_POLYGON,
  ETH_BSC,
  OP,
  PORTAL_ETH_CELO,
  USDC_ARBITRUM,
  USDC_ARBITRUM_GOERLI,
  USDC_AVALANCHE,
  USDC_BASE,
  USDC_BSC,
  USDC_CELO,
  USDC_MAINNET,
  USDC_OPTIMISM,
  USDC_OPTIMISM_GOERLI,
  USDC_POLYGON,
  USDC_POLYGON_MUMBAI,
  USDT,
  USDT_ARBITRUM_ONE,
  USDT_AVALANCHE,
  USDT_BSC,
  USDT_OPTIMISM,
  USDT_POLYGON,
  WBTC,
  WBTC_ARBITRUM_ONE,
  WBTC_CELO,
  WBTC_OPTIMISM,
  WBTC_POLYGON,
  WETH_AVALANCHE,
  WETH_POLYGON,
  WETH_POLYGON_MUMBAI,
  WRAPPED_NATIVE_CURRENCY,
  nativeOnChain,
} from "./tokens";

type ChainTokenList = {
  readonly [chainId: number]: Token[];
};

type ChainCurrencyList = {
  readonly [chainId: number]: CurrencyInfo[];
};

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
);

function buildCurrencyInfo(commonBase: Currency): CurrencyInfo {
  const logoUrl = commonBase.isNative
    ? getNativeLogoURI(commonBase.chainId)
    : getTokenLogoURI(commonBase.address, commonBase.chainId);
  return {
    currency: commonBase,
    logoUrl,
    safetyLevel: SafetyLevel.Verified,
    isSpam: false,
  } as CurrencyInfo;
}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.MAINNET]: [
    nativeOnChain(ChainId.MAINNET),
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
    WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET] as Token,
  ].map(buildCurrencyInfo),
  [ChainId.GOERLI]: [
    nativeOnChain(ChainId.GOERLI),
    WRAPPED_NATIVE_CURRENCY[ChainId.GOERLI] as Token,
  ].map(buildCurrencyInfo),
  [ChainId.SEPOLIA]: [
    nativeOnChain(ChainId.SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA] as Token,
  ].map(buildCurrencyInfo),
  [ChainId.ARBITRUM_ONE]: [
    nativeOnChain(ChainId.ARBITRUM_ONE),
    ARB,
    DAI_ARBITRUM_ONE,
    USDC_ARBITRUM,
    USDT_ARBITRUM_ONE,
    WBTC_ARBITRUM_ONE,
    WRAPPED_NATIVE_CURRENCY[ChainId.ARBITRUM_ONE] as Token,
  ].map(buildCurrencyInfo),
  [ChainId.ARBITRUM_GOERLI]: [
    nativeOnChain(ChainId.ARBITRUM_GOERLI),
    WRAPPED_NATIVE_CURRENCY[ChainId.ARBITRUM_GOERLI] as Token,
    USDC_ARBITRUM_GOERLI,
  ].map(buildCurrencyInfo),

  [ChainId.OPTIMISM]: [
    nativeOnChain(ChainId.OPTIMISM),
    OP,
    DAI_OPTIMISM,
    USDC_OPTIMISM,
    USDT_OPTIMISM,
    WBTC_OPTIMISM,
    WETH9[ChainId.OPTIMISM],
  ].map(buildCurrencyInfo),
  [ChainId.OPTIMISM_GOERLI]: [
    nativeOnChain(ChainId.OPTIMISM_GOERLI),
    USDC_OPTIMISM_GOERLI,
  ].map(buildCurrencyInfo),

  [ChainId.BASE]: [
    nativeOnChain(ChainId.BASE),
    WRAPPED_NATIVE_CURRENCY[ChainId.BASE] as Token,
    USDC_BASE,
  ].map(buildCurrencyInfo),
  [ChainId.BLAST]: [
    nativeOnChain(ChainId.BLAST),
    WRAPPED_NATIVE_CURRENCY[ChainId.BLAST] as Token,
  ].map(buildCurrencyInfo),

  [ChainId.POLYGON]: [
    nativeOnChain(ChainId.POLYGON),
    WETH_POLYGON,
    USDC_POLYGON,
    DAI_POLYGON,
    USDT_POLYGON,
    WBTC_POLYGON,
  ].map(buildCurrencyInfo),
  [ChainId.POLYGON_MUMBAI]: [
    nativeOnChain(ChainId.POLYGON_MUMBAI),
    WRAPPED_NATIVE_CURRENCY[ChainId.POLYGON_MUMBAI] as Token,
    USDC_POLYGON_MUMBAI,
    WETH_POLYGON_MUMBAI,
  ].map(buildCurrencyInfo),

  [ChainId.CELO]: [
    nativeOnChain(ChainId.CELO),
    CEUR_CELO,
    CUSD_CELO,
    PORTAL_ETH_CELO,
    USDC_CELO,
    WBTC_CELO,
  ].map(buildCurrencyInfo),

  [ChainId.CELO_ALFAJORES]: [
    nativeOnChain(ChainId.CELO_ALFAJORES),
    CUSD_CELO_ALFAJORES,
    CEUR_CELO_ALFAJORES,
  ].map(buildCurrencyInfo),

  [ChainId.BNB]: [
    nativeOnChain(ChainId.BNB),
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    ETH_BSC,
    BTC_BSC,
    BUSD_BSC,
  ].map(buildCurrencyInfo),

  [ChainId.AVALANCHE]: [
    nativeOnChain(ChainId.AVALANCHE),
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE,
  ].map(buildCurrencyInfo),
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.MAINNET]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.MAINNET],
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
  ],
  [ChainId.BNB]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BNB],
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    BTC_BSC,
    BUSD_BSC,
    ETH_BSC,
  ],
  [ChainId.AVALANCHE]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.AVALANCHE],
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE,
  ],
};

export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(
        ChainId.MAINNET,
        "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
        8,
        "cDAI",
        "Compound Dai"
      ),
      new Token(
        ChainId.MAINNET,
        "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
        8,
        "cUSDC",
        "Compound USD Coin"
      ),
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT],
  ],
};

/// TODO: review

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  // [SupportedChainId.MAINNET]: [
  //   ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.MAINNET],
  //   DAI,
  //   USDC_MAINNET,
  //   USDT,
  //   WBTC
  // ],
  //   [SupportedChainId.OPTIMISM]: [
  //     ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.OPTIMISM],
  //     DAI_OPTIMISM,
  //     USDT_OPTIMISM,
  //     WBTC_OPTIMISM
  //   ],
  //   [SupportedChainId.ARBITRUM_ONE]: [
  //     ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ARBITRUM_ONE],
  //     DAI_ARBITRUM_ONE,
  //     USDT_ARBITRUM_ONE,
  //     WBTC_ARBITRUM_ONE
  //   ],
  //   [SupportedChainId.POLYGON]: [
  //     ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.POLYGON],
  //     DAI_POLYGON,
  //     USDC_POLYGON,
  //     USDT_POLYGON,
  //     WETH_POLYGON
  //   ],
  //   [SupportedChainId.CELO]: [CUSD_CELO, CEUR_CELO, CMC02_CELO, PORTAL_USDC_CELO, PORTAL_ETH_CELO],
  //   [SupportedChainId.GOERLI]: [
  //     ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.GOERLI]
  //     // ...Object.values(eulerTokens)
  //     // ...Object.values(getAaveTokens(SupportedChainId.GOERLI)),
  //     // ...Object.values(getCompoundTokens(SupportedChainId.GOERLI))
  //   ],
  //   [SupportedChainId.POLYGON_MUMBAI]: [
  //     WMATIC_POLYGON_MUMBAI,
  //     AAVE_MUMBAI,
  //     DAI_MUMBAI,
  //     WBTC_MUMBAI,
  //     LINK_MUMBAI,
  //     USDT_MUMBAI,
  //     USDC_MUMBAI
  //   ],
  // [SupportedChainId.SEPOLIA]: [DAI_SEPOLIA, AAVE_SEPOLIA, LINK_SEPOLIA]
};
export const ADDITIONAL_BASES: {
  [chainId: number]: { [tokenAddress: string]: Token[] };
} = {
  // [SupportedChainId.MAINNET]: {
  //   // '0xF16E4d813f4DcfDe4c5b44f305c908742De84eF0': [ETH2X_FLI],
  //   // [rETH2.address]: [sETH2],
  //   // [SWISE.address]: [sETH2],
  //   // [FEI.address]: [TRIBE],
  //   // [TRIBE.address]: [FEI],
  //   // [FRAX.address]: [FXS],
  //   // [FXS.address]: [FRAX],
  //   // [WBTC.address]: [renBTC],
  //   // [renBTC.address]: [WBTC]
  // }
};
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId: number]: { [tokenAddress: string]: Token[] };
} = {
  // [SupportedChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token]
  // }
};
