import { signTypedData } from 'wallet/src/features/wallet/signing/signing'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { CustomUserProperties, SwapEventName } from '@uniswap/analytics-events'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import {
  FlatFeeOptions,
  SwapRouter,
  UNIVERSAL_ROUTER_ADDRESS
} from '@uniswap/universal-router-sdk'
import { FeeOptions, Pool, toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, useTrace } from 'analytics'
import { useTotalBalancesUsdForAnalytics } from 'graphql/data/apollo/TokenBalancesProvider'
import { useGetTransactionDeadline } from 'hooks/useTransactionDeadline'
import { t } from 'i18n'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import {
  formatCommonPropertiesForTrade,
  formatSwapSignedAnalyticsEventProperties
} from 'lib/utils/analytics'
import { useCallback } from 'react'
import { ClassicTrade, TradeFillType } from 'state/routing/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { trace } from 'tracing/trace'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import isZero from 'utils/isZero'
import {
  didUserReject,
  swapErrorToUserReadableMessage
} from 'utils/swapErrorToUserReadableMessage'
import { getWalletMeta } from 'utils/walletMeta'
import { useAccount } from 'wagmi'
import { PermitSignature } from './usePermitAllowance'
import { useContract } from './useContract'
import { getLekkerFactory } from 'constants/contracts/helpers'
import LekkerFactoryABI from 'constants/contracts/abis/LekkerFactory.json'
import { parseUnits, splitSignature } from 'ethers/lib/utils'
import { encodePath } from 'utils/uniswapPaths'
import { currencyId } from 'utils/currencyId'
import { ethers, TypedDataDomain } from 'ethers'
import { AllowanceTransfer, PERMIT2_ADDRESS } from '@uniswap/permit2-sdk'
import IPERMIT2_ABI from 'uniswap/src/abis/IPermit2.json'
import { Permit2 } from 'uniswap/src/abis/types'
import { parse } from 'path'
import {
  compressPermit,
  cutSelector,
  trim0x
} from 'utils/permit-pack/permitHelpers'

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor () {
    super(t`Your swap is expected to fail.`)
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor () {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  permit?: PermitSignature
  feeOptions?: FeeOptions
  flatFeeOptions?: FlatFeeOptions
}

export function useUniversalRouterSwapCallback (
  parsedAmountInput: CurrencyAmount<Currency> | undefined,
  trade: ClassicTrade | undefined,
  fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number },
  options: SwapOptions
) {
  const { account, chainId, provider } = useWeb3React()
  const connectorName = useAccount().connector?.name

  const analyticsContext = useTrace()
  const blockNumber = useBlockNumber()
  const getDeadline = useGetTransactionDeadline()
  const isAutoSlippage = useUserSlippageTolerance()[0] === 'auto'
  const portfolioBalanceUsd = useTotalBalancesUsdForAnalytics()

  const factoryContract = useContract(
    getLekkerFactory(chainId),
    LekkerFactoryABI
  )

  const permitContract = useContract(PERMIT2_ADDRESS, IPERMIT2_ABI.abi, true)

  return useCallback(
    (): Promise<{
      type: TradeFillType.Classic
      response: TransactionResponse
      deadline?: BigNumber
    }> =>
      trace({ name: 'Swap (Classic)', op: 'swap.classic' }, async trace => {
        try {
          if (!account) throw new Error('missing account')
          if (!chainId) throw new Error('missing chainId')
          if (!provider) throw new Error('missing provider')
          if (!trade) throw new Error('missing trade')
          if (!factoryContract) throw new Error('missing factory contract')
          //TODO: remember to reactivate
          // if (!options.permit) throw new Error("missing options");
          if (!permitContract) throw new Error('missing permit contract')
          if (!parsedAmountInput)
            throw new Error('missing user parsedAmountInput')
          const connectedChainId = await provider.getSigner().getChainId()
          if (chainId !== connectedChainId) throw new WrongChainError()

          const deadline = await getDeadline()

          trace.setData(
            'slippageTolerance',
            options.slippageTolerance.toFixed(2)
          )

          let permitSingle
          let sig
          // TODO: This needs review - temporary measure.
          // Ideally just use the uniswap flow, but for some reason after 1 tx is executed, the signature is lost and
          // not propted for renewal.
          if (!options.permit) {
            console.log('~> WE DONT HAVE A PERMIT', options.permit)
            const signer = provider.getSigner()
            permitSingle = {
              details: {
                token: currencyId(trade.outputAmount.currency),
                amount: parseUnits(
                  parsedAmountInput.toSignificant(6), // Collateral currency amount - provided by the user
                  trade.outputAmount.currency.decimals
                ),
                expiration: 2n ** 48n - 1n,
                nonce: (
                  await permitContract.allowance(
                    account,
                    currencyId(trade.outputAmount.currency),
                    factoryContract.address
                  )
                ).nonce
              },
              spender: factoryContract.address,
              sigDeadline: 2n ** 48n - 1n
            }

            const data = AllowanceTransfer.getPermitData(
              permitSingle,
              PERMIT2_ADDRESS,
              chainId
            )
            // const sig = splitSignature(options.permit.signature);
            sig = splitSignature(
              await signer._signTypedData(
                data.domain as TypedDataDomain,
                data.types,
                data.values
              )
            )
          } else {
            //  Create the permitSingle structure
            console.log('~> WE HAVE A PERMIT', options.permit)

            permitSingle = {
              details: {
                token: options.permit.details.token,
                amount: options.permit.details.amount,
                expiration: options.permit.details.expiration,
                nonce: options.permit.details.nonce
              },
              spender: options.permit.spender,
              sigDeadline: options.permit.sigDeadline
            }
            sig = splitSignature(options.permit.signature)
          }

          // Encode permit data
          const permitCall = cutSelector(
            permitContract.interface.encodeFunctionData('permit', [
              account,
              permitSingle,
              sig.r + trim0x(sig.yParityAndS)
            ])
          )

          const permit = compressPermit(permitCall)
          console.log('Start 5')

          const functionCallData = factoryContract.interface.encodeFunctionData(
            'createAavePool',
            [
              currencyId(trade.outputAmount.currency),
              currencyId(trade.inputAmount.currency),
              parseUnits(
                parsedAmountInput.toSignificant(6), // Collateral currency amount - provided by the user
                trade.outputAmount.currency.decimals
              ),
              parseUnits(
                trade.inputAmount.toSignificant(6), // Debt currency amount - paid to the DEX
                trade.inputAmount.currency.decimals
              ),
              encodePath(
                trade.routes[0].path.map((a: { address: string }) => a.address),
                //@ts-ignore
                trade.routes[0].pools.map((a: { fee: number }) => a.fee)
              ),
              ethers.utils.formatBytes32String('FIKA'),
              true // usePermit2
            ]
          )
          console.log('Start 6')

          const tx = {
            from: account,
            to: factoryContract.address,
            data: factoryContract.interface.encodeFunctionData(
              'permitAndCall',
              [
                currencyId(trade.outputAmount.currency) + trim0x(permit),
                functionCallData
              ]
            )
            // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
            // value: trade.inputAmount.quotient.toString(),
          }
          console.log('Start 7')

          let gasLimit: BigNumber
          try {
            const gasEstimate = await provider.estimateGas(tx)
            console.log(
              '~> ~ file: useUniversalRouter.ts:387 ~ trace ~ gasEstimate:',
              gasEstimate.toString()
            )

            gasLimit = calculateGasMargin(gasEstimate)
            trace.setData('gasLimit', gasLimit.toNumber())
          } catch (gasError) {
            sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
              ...formatCommonPropertiesForTrade(
                trade,
                options.slippageTolerance
              ),
              ...analyticsContext,
              client_block_number: blockNumber,
              tx,
              isAutoSlippage
            })
            console.warn(gasError)
            throw new GasEstimationError()
          }

          const response = await trace.child(
            { name: 'Send transaction', op: 'wallet.send_transaction' },
            async walletTrace => {
              try {
                return await provider
                  .getSigner()
                  .sendTransaction({ ...tx, gasLimit })
              } catch (error) {
                if (didUserReject(error)) {
                  walletTrace.setStatus('cancelled')
                  throw new UserRejectedRequestError(
                    swapErrorToUserReadableMessage(error)
                  )
                } else {
                  throw error
                }
              }
            }
          )
          sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
            ...formatSwapSignedAnalyticsEventProperties({
              trade,
              timeToSignSinceRequestMs: trace.now(),
              allowedSlippage: options.slippageTolerance,
              fiatValues,
              txHash: response.hash,
              portfolioBalanceUsd
            }),
            ...analyticsContext,
            // TODO (WEB-2993): remove these after debugging missing user properties.
            [CustomUserProperties.WALLET_ADDRESS]: account,
            [CustomUserProperties.WALLET_TYPE]: connectorName,
            [CustomUserProperties.PEER_WALLET_AGENT]: provider
              ? getWalletMeta(provider)?.agent
              : undefined
          })
          if (tx.data !== response.data) {
            sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
              txHash: response.hash,
              expected: tx.data,
              actual: response.data,
              ...analyticsContext
            })
            if (
              !response.data ||
              response.data.length === 0 ||
              response.data === '0x'
            ) {
              throw new ModifiedSwapError()
            }
          }
          return { type: TradeFillType.Classic as const, response, deadline }
        } catch (error: unknown) {
          if (error instanceof GasEstimationError) {
            throw error
          } else if (error instanceof UserRejectedRequestError) {
            trace.setStatus('cancelled')
            throw error
          } else if (error instanceof ModifiedSwapError) {
            trace.setError(error, 'data_loss')
            throw error
          } else {
            trace.setError(error)
            throw Error(swapErrorToUserReadableMessage(error))
          }
        }
      }),
    [
      account,
      chainId,
      provider,
      trade,
      getDeadline,
      options.slippageTolerance,
      options.permit,
      options.feeOptions,
      options.flatFeeOptions,
      fiatValues,
      portfolioBalanceUsd,
      analyticsContext,
      connectorName,
      blockNumber,
      isAutoSlippage
    ]
  )
}
