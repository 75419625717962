import { useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { resetState, updatePositionData, setPositionDataError, setLoading } from './reducer';
import { AssetState, PositionData } from './types';
import LekkerLensABI from 'uniswap/src/abis/LekkerLens.json';
import { getAavePoolAddress, getLekkerFactory, getLensContract } from 'constants/contracts/helpers';
import { useContract } from 'hooks/useContract';
import { generateTokensFromList } from 'constants/lists';

export function useAssetState(): AssetState {
  return useAppSelector((state) => state.assetState);
}

export function usePositionData(chainId: number) {
  return useAppSelector((state) => state.assetState.positions[chainId]);
}

export function usePositionDataError(chainId: number) {
  return useAppSelector((state) => state.assetState.errors[chainId]);
}

export function useAssetActions(chainId: number) {
  const dispatch = useAppDispatch();
  const lensContractAddress = getLensContract(chainId);
  const lekkerFactoryAddress = getLekkerFactory(chainId);
  const aavePoolAddress = getAavePoolAddress(chainId);
  const lensContract = useContract(lensContractAddress, LekkerLensABI);

  
  const fetchPositionData = useCallback(async () => {
    try {
      // Set loading to true before fetching data
      dispatch(setLoading({ chainId, isLoading: true }));

      const tokens = generateTokensFromList(chainId);
      // TODO: we need to get all data, not just aave
      let data = await lensContract?.getAavePoolData(lekkerFactoryAddress, aavePoolAddress);
      data = [...data].reverse();


      const result = data.map((entry: any) => {
    
        return {
          poolAddress: entry.lekkerPoolAddress,
          collateral: entry.collateral,
          debt: entry.debt,
          lender: 'Aave', // Set this based on the source of data, e.g., Aave or Spark
          stats: {
            totalCollateralBase: entry.totalCollateralBase.toString(),
            totalDebtBase: entry.totalDebtBase.toString(),
            availableBorrowsBase: entry.availableBorrowsBase.toString(),
            currentLiquidationThreshold: entry.currentLiquidationThreshold.toString(),
            ltv: entry.ltv.toString(),
            currentLtv: entry.totalCollateralBase.isZero()
              ? "0"
              : Math.floor((entry.totalDebtBase * 10000) / entry.totalCollateralBase).toString(),
            healthFactor: entry.healthFactor.toString(),
            leverage: entry.totalDebtBase.isZero()
              ? "0"
              : Math.floor(entry.totalCollateralBase * 10 / (entry.totalCollateralBase - entry.totalDebtBase)).toString(),
          },
        };
      });

      dispatch(updatePositionData({ chainId, data: result }));
      dispatch(setLoading({ chainId, isLoading: false }));

    } catch (error) {

      dispatch(setPositionDataError({ chainId, error: error.message }));
    }
  }, [dispatch, chainId, lensContract, lekkerFactoryAddress, aavePoolAddress]);

  useEffect(() => {
    fetchPositionData();
  }, [fetchPositionData]);
  

  return {
    updatePositionData: (chainId: number, data: PositionData[]) => 
      dispatch(updatePositionData({ chainId, data })),

    setAaveDataError: (chainId: number, error: string) => 
      dispatch(setPositionDataError({ chainId, error })),
    resetAssetState: () => dispatch(resetState()),
    fetchPositionData,
  };
}
