import { ApolloError } from '@apollo/client';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ChainId, Currency, Token } from '@uniswap/sdk-core';
import { DoubleCurrencyAndChainLogo } from 'components/DoubleLogo';
import Row from 'components/Row';
import { Table } from 'components/Table';
import { Cell } from 'components/Table/Cell';
import { ClickableHeaderRow, HeaderArrow, HeaderSortText } from 'components/Table/styled';
import { NameText } from 'components/Tokens/TokenTable';
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants';
import { useSelector } from 'react-redux';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ExternalLink, ThemedText } from 'theme/components';
import { useFormatter, NumberType } from 'utils/formatNumbers';
import { usePositionData, useAssetActions, useAssetState } from 'state/assetData/hooks';
import { AppState } from 'state/reducer';
import { AaveAssetData, AssetState, PositionData, SerializedBigNumber } from 'state/assetData/types';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { atomWithReset, useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils';
import { OrderDirection, gqlToCurrency, unwrapToken } from 'graphql/data/util';
import { useToken } from 'hooks/Tokens';
import { generateTokenFromAddress } from 'constants/lists';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { useAtom } from 'jotai';
import { Trans } from 'i18n';
import { MouseoverTooltip } from 'components/Tooltip';

export enum PositionSortFields {
  LTV = 'LTV',
  TotalCollateralBase = 'Total Collateral Base',
  TotalDebtBase = 'Total Debt Base',
  HealthFactor = 'Health Factor',
  Leverage = 'Leverage',
}

const HEADER_DESCRIPTIONS: Record<PositionSortFields, ReactNode | undefined> = {
  [PositionSortFields.LTV]: undefined,
  [PositionSortFields.TotalCollateralBase]: undefined,
  [PositionSortFields.TotalDebtBase]: undefined,
  [PositionSortFields.HealthFactor]: undefined,
  [PositionSortFields.Leverage]: (
    <Trans>
      1 day APR refers to the amount of trading fees relative to total value locked (TVL) within a pool. 1 day APR = 24H
      Fees / TVL
    </Trans>
  ),
}

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
`;

interface PositionsTableValues {
  index: number | null;
  positionDescription: ReactElement;
  lender: string | null;
  ltv: string | null;
  totalCollateralBase: BigNumber;
  totalDebtBase: BigNumber;
  healthFactor: BigNumber;
  leverage: BigNumber;
}

export enum PositionsTableColumns {
  Index,
  PositionDescription,
  Lender,
  LTV,
  Collateral,
  Debt,
  HealthFactor,
  Leverage,
}

function PositionDescription({
  token0,
  token1,
  chainId,
  poolAddress
}: {
  token0: Token
  token1: Token
  chainId: ChainId
  poolAddress: string
}) {
  const currencies = [token0, token1]

  const explorerUrl = getExplorerLink(42161, poolAddress, ExplorerDataType.ADDRESS)

  return (
    <ExternalLink href={explorerUrl} target="_blank">
    <Row gap="sm">
      <DoubleCurrencyAndChainLogo chainId={chainId} currencies={currencies} size={28} />
      <NameText>
        {token0.symbol}/{token1.symbol}
      </NameText>
    </Row>
    </ExternalLink>

  )
}


// Used to keep track of sorting state for Pool Tables
// declared as atomWithReset because sortMethodAtom and sortAscendingAtom are shared across multiple Pool Table instances - want to be able to reset sorting state between instances
export const sortMethodAtom = atomWithReset<PositionSortFields>(PositionSortFields.Leverage)
export const sortAscendingAtom = atomWithReset<boolean>(false)

function useSetSortMethod(newSortMethod: PositionSortFields) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
  const setSortAscending = useUpdateAtom(sortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending((sortAscending) => !sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, newSortMethod])
}

const HEADER_TEXT: Record<PositionSortFields, ReactNode> = {
  [PositionSortFields.LTV]: <Trans>Max LTV</Trans>,
  [PositionSortFields.TotalCollateralBase]: <Trans>Collateral</Trans>,
  [PositionSortFields.TotalDebtBase]: <Trans>Debt</Trans>,
  [PositionSortFields.HealthFactor]: <Trans>Health Factor</Trans>,
  [PositionSortFields.Leverage]: <Trans>Leverage</Trans>,
}

function PositionTableHeader({
  category,
  isCurrentSortMethod,
  direction,
}: {
  category: PositionSortFields
  isCurrentSortMethod: boolean
  direction: OrderDirection
}) {
  const handleSortCategory = useSetSortMethod(category)
  return (
    <MouseoverTooltip disabled={!HEADER_DESCRIPTIONS[category]} text={HEADER_DESCRIPTIONS[category]} placement="top">
      <ClickableHeaderRow $justify="flex-end" onClick={handleSortCategory}>
        {isCurrentSortMethod && <HeaderArrow direction={direction} />}
        <HeaderSortText $active={isCurrentSortMethod}>{HEADER_TEXT[category]}</HeaderSortText>
      </ClickableHeaderRow>
    </MouseoverTooltip>
  )
}


export function TopPositionTable() {
  const dispatch = useAppDispatch();
  useAssetActions(42161)


  const allPositions = useAppSelector((state) => state.assetState.positions[42161]);

  // Filter out positions with zero collateral
  const positions = useMemo(() => {
    return allPositions?.filter(position => 
      position.stats?.totalCollateralBase && 
      !BigNumber.from(position.stats.totalCollateralBase).isZero()
    ) || [];
  }, [allPositions]);
  
  const loading = (useAppSelector((state) => state.assetState.loading[42161]) ?? true);

  const error = useAppSelector((state) => state.assetState.errors[42161]);


  const sortMethod = useAtomValue(sortMethodAtom);
  const sortAscending = useAtomValue(sortAscendingAtom);

  const resetSortMethod = useResetAtom(sortMethodAtom);
  const resetSortAscending = useResetAtom(sortAscendingAtom);

  useEffect(() => {
    resetSortMethod();
    resetSortAscending();
  }, [resetSortMethod, resetSortAscending]);

  


  return (
    <TableWrapper>
      <PositionDataTable
        positions={positions}
        loading={loading}
        error={error}
        chainId={42161}
        maxWidth={1200}
      />
    </TableWrapper>
  );
}


export function PositionDataTable({
  positions,
  loading,
  error,
  chainId,
  maxWidth,
}: {
  positions?: PositionData[];
  loading: boolean;
  error?: any;
  chainId: number;
  maxWidth?: number;
}) {

  const { formatNumber } = useFormatter();

  const positionsTableValues: PositionsTableValues[] | undefined = useMemo(
    
    () =>
      
      positions?.map((position, index) => {
       
        const positionSortRank = index + 1
        return {
        index: positionSortRank,
          positionDescription: (
            <PositionDescription
              token0={generateTokenFromAddress(chainId, position.collateral)}
              token1={generateTokenFromAddress(chainId, position.debt)}
              chainId={chainId}
              poolAddress={position.poolAddress}
            />
          ),
        lender: position.lender,
        ltv: position.stats?.ltv ?? null,
        totalCollateralBase: BigNumber.from(position.stats?.totalCollateralBase) ?? 0,
        totalDebtBase: BigNumber.from(position.stats?.totalDebtBase) ?? 0,
        healthFactor: BigNumber.from(position.stats?.healthFactor) ?? 0,
        leverage: BigNumber.from(position.stats?.leverage) ?? 0,
        }
      }) ?? [],
    [positions]
  );

  const showLoadingSkeleton = loading || !!error;

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<PositionsTableValues>();
    return [
      columnHelper.accessor((row) => row.index, {
        id: 'index',
        header: () => (
          <Cell justifyContent="center" minWidth={34}>
            <ThemedText.BodySecondary>#</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (index) => (
          <Cell justifyContent="center" loading={showLoadingSkeleton} minWidth={34}>
            <ThemedText.BodySecondary>{index.getValue?.()}</ThemedText.BodySecondary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.positionDescription, {
        id: 'positionDescription',
        header: () => (
          <Cell justifyContent="flex-start" width={140} grow>
            <ThemedText.BodySecondary>Token Pair</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (positionDescription) => (
          <Cell justifyContent="flex-start" loading={showLoadingSkeleton} width={140} grow>
            <ThemedText.BodyPrimary>{positionDescription.getValue?.()}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
   
      columnHelper.accessor((row) => row.leverage, {
        id: 'leverage',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>Leverage</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (leverage) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow>
            <ThemedText.BodyPrimary>{leverage.getValue && Number(leverage.getValue()) != 0 ?  parseFloat(formatUnits(leverage.getValue(), 1)) + "x" : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
     
      columnHelper.accessor((row) => row.totalCollateralBase, {
        id: 'totalCollateralBase',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Collateral</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (totalCollateralBase) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>
            {totalCollateralBase.getValue
                ? formatNumber({
                    input: parseFloat(formatUnits(totalCollateralBase.getValue(), 8)),
                    type: NumberType.FiatTokenStats,
                  })
                : 'N/A'}
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.totalDebtBase, {
        id: 'totalDebtBase',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Debt</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (totalDebtBase) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>
            {totalDebtBase.getValue
                ? formatNumber({
                    input: parseFloat(formatUnits(totalDebtBase.getValue(), 8)),
                    type: NumberType.FiatTokenStats,
                  })
                : 'N/A'}            
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.healthFactor, {
        id: 'healthFactor',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Health Factor</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (healthFactor) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>{healthFactor.getValue && Number(healthFactor.getValue()) != 0 && Number(healthFactor.getValue()) < 10 ** 59 ? parseFloat(formatUnits(healthFactor.getValue(), 18)).toFixed(2) : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.ltv, {
        id: 'ltv',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>Max LTV</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (ltv) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow>
            <ThemedText.BodyPrimary>{ltv.getValue?.() && Number(ltv.getValue()) != 0 ? (parseFloat(ltv.getValue()!) / 100).toFixed(0 )+ '%' : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.lender, {
        id: 'lender',
        header: () => (
          <Cell justifyContent="flex-start" width={120} grow>
            <ThemedText.BodySecondary>Market</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (lender) => (
          <Cell justifyContent="flex-start" loading={showLoadingSkeleton} width={120} grow>
            <ThemedText.BodyPrimary>{lender.getValue?.()}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
     
    ];
  }, [formatNumber, showLoadingSkeleton]);

  return (
    <Table
      columns={columns}
      data={positionsTableValues ?? []}
      loading={loading}
      error={error}
      maxWidth={maxWidth}
    />
  );
}


