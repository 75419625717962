import { svgPaths as containerPaths } from "./Container";
import { svgPaths as emblemPaths } from "./Emblem";

export enum UniconAttributes {
  GradientStart = 0,
  GradientEnd = 2,
  Container = 8, //2,
  Shape = 4, //3,
}

export const UniconAttributesArray: UniconAttributes[] = [
  UniconAttributes.GradientStart,
  UniconAttributes.GradientEnd,
  UniconAttributes.Container,
  UniconAttributes.Shape,
];

export interface UniconAttributesToIndices {
  [UniconAttributes.GradientStart]: number;
  [UniconAttributes.GradientEnd]: number;
  [UniconAttributes.Container]: number;
  [UniconAttributes.Shape]: number;
}

export interface UniconAttributeData {
  [UniconAttributes.GradientStart]: string;
  [UniconAttributes.GradientEnd]: string;
  [UniconAttributes.Container]: React.SVGProps<SVGPathElement>[];
  [UniconAttributes.Shape]: React.SVGProps<SVGPathElement>[];
}

export const gradientStarts = [
  "#FFFF00", // Light yellow as the base color
  "#FFFACD", // A slightly darker yellow
  "#FFD700", // Gold, a deeper yellow
  "#FFEA00", // Bright orange-yellow
  "#FFD800", // Darker orange-yellow
  "#FFB74D", // Deep orange-yellow
  "#FFA500", // Medium orange-yellow
  "#FF8C00", // Dark orange-yellow
  "#FF4500", // Bright red-orange
  "#CD8500", // Dark red-orange
];

export const blurs = [
  "#FFEBCD", // Very light yellow
  "#FFDAB9", // Light peach
  "#FFB080", // Orange-peach
  "#FFA07A", // Peach-brown
  "#FF8C66", // Brownish-orange
  "#FF704D", // Deep orange
  "#FF5733", // Bright red-orange
  "#FF4040", // Dark red-orange
  "#FF2400", // Bright maroon
  "#CD0000", // Dark maroon
];

export const gradientEnds = [
  "#FFD1DC", // Light pink
  "#FFB3E6", // Pink
  "#FF99AC", // Rose pink
  "#FF7AA2", // Coral pink
  "#FF5A41", // Salmon pink
  "#FF3D00", // Bright orange
  "#FF2800", // Dark orange
  "#FF1900", // Bright red
  "#CD0000", // Dark red
  "#B22222", // Maroon
];

export const UniconNumOptions = {
  [UniconAttributes.GradientStart]: gradientStarts.length,
  [UniconAttributes.GradientEnd]: gradientEnds.length,
  [UniconAttributes.Container]: containerPaths.length,
  [UniconAttributes.Shape]: emblemPaths.length,
};
