// LeverageSlider.tsx
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import styled, { keyframes } from "styled-components";
import { SliderTooltip } from "./SliderTooltip";
import { opacify } from "theme/utils";


const StyledSlider = styled(Slider)`
  width: 75%;
  margin: 32px;
  .rc-slider-rail {
    background-color: ${({ theme }) => opacify(0, theme.surface1)};
    height: 66px;
    margin-top: -33px;
  }

  .rc-slider-track {
    background-color: ${({ theme }) => opacify(0, theme.surface1)};
    height: 66px;
    margin-top: -33px;
  }

  .rc-slider-handle {
    --handle-height: 30px;
    height: var(--handle-height);
    width: calc(var(--handle-height) * 1.2);
    margin-top: calc(var(--handle-height) / -2);
    border: 4px solid ${({ theme }) => theme.surface1};
    opacity: 1;
    background-color: ${({ theme }) => theme.neutral3};
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-weight: regular;
    user-select: none;
    &:active {
      box-shadow: none;
      border-color: ${({ theme }) => theme.surface1};
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

interface LeverageSliderProps {
  value: number | undefined;
  onChange: (value: number | number[]) => void;
}

const LeverageSlider: React.FC<LeverageSliderProps> = ({ value, onChange }) => {

  return (
    <StyledSlider
      min={1.1}
      max={10}
      value={value}
      step={0.1}
      onChange={onChange}
      handleRender={(renderProps: any) => {
        return (
          <div {...renderProps.props}>
            <SliderTooltip>{value}x</SliderTooltip>
                  </div>
        );
      }}
    />
  );
};

export default LeverageSlider;
