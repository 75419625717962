import { ChainId, Currency, Token } from '@uniswap/sdk-core'

// Lists we use as fallbacks on chains that our backend doesn't support
const COINGECKO_AVAX_LIST = 'https://tokens.coingecko.com/avalanche/all.json'
const UNISWAP_LIST = 'https://tokens.uniswap.org'

export const DEFAULT_INACTIVE_LIST_URLS: string[] = [COINGECKO_AVAX_LIST]

interface TokenMetadata {
  chainId: number
  address: string
  decimals: number
  symbol: string
  name: string
}

export const TOKENS: { [chainId: number]: any } = {
  [ChainId.MAINNET]: [],
  [ChainId.ARBITRUM_ONE]: [
    {
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'
    },
    {
      name: 'Chainlink Token',
      symbol: 'LINK',
      decimals: 18,
      address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4'
    },
    {
      name: 'Bridged USDC',
      symbol: 'USDC.e',
      decimals: 6,
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
    },
    {
      name: 'Wrapped Bitcoin',
      symbol: 'WBTC',
      decimals: 8,
      address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f'
    },
    {
      name: 'Wrapped Ethereum',
      symbol: 'WETH',
      decimals: 18,
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'
    },
    {
      name: 'Aave Token',
      symbol: 'AAVE',
      decimals: 18,
      address: '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196'
    },
    {
      name: 'Stasis Euro',
      symbol: 'EURS',
      decimals: 2,
      address: '0xD22a58f79e9481D1a88e00c343885A588b34b68B'
    },
    {
      name: 'Wrapped liquid staked Ether 2.0',
      symbol: 'wstETH',
      decimals: 18,
      address: '0x5979D7b546E38E414F7E9822514be443A4800529'
    },
    {
      name: 'Rocket Pool ETH',
      symbol: 'rETH',
      decimals: 18,
      address: '0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8'
    },
    {
      name: 'Liquity USD',
      symbol: 'LUSD',
      decimals: 18,
      address: '0x93b346b6BC2548dA6A1E7d98E9a421B42541425b'
    },
    {
      name: 'USDC Coin',
      symbol: 'USDC',
      decimals: 6,
      address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'
    },
    {
      name: 'Frax',
      symbol: 'FRAX',
      decimals: 18,
      address: '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F'
    },
    {
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18,
      address: '0x912CE59144191C1204E64559FE8253a0e49E6548'
    }
  ]
}

export function generateTokensFromList (chainId: number): Token[] {
  const tokensArray = TOKENS[chainId]
  if (!tokensArray || !Array.isArray(tokensArray)) {
    throw new Error(`No tokens found for chainId: ${chainId}`)
  }

  return tokensArray.map(
    tokenData =>
      new Token(
        chainId,
        tokenData.address,
        tokenData.decimals,
        tokenData.symbol,
        tokenData.name
      )
  )
}

export function generateTokenFromAddress (
  chainId: number,
  address: string
): Token {
  const tokensArray = TOKENS[chainId]

  if (!tokensArray || !Array.isArray(tokensArray)) {
    throw new Error(`No tokens found for chainId: ${chainId}`)
  }

  const tokenData = tokensArray.find(
    token => token.address.toLowerCase() === address.toLowerCase()
  )

  if (!tokenData) {
    throw new Error(
      `Token not found for address: ${address} on chainId: ${chainId}`
    )
  }

  return new Token(
    chainId,
    tokenData.address,
    tokenData.decimals,
    tokenData.symbol,
    tokenData.name
  )
}
